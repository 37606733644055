/** @format */
import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import woman1 from "../images/ulan.jpeg";
import woman2 from "../images/gladys.jpeg";
import woman3 from "../images/salome.jpeg";
import grace from "../images/grace.jpeg";
import peace from "../images/peace.jpeg";
import dorcas from "../images/dorcas.jpeg";
import sedoo from "../images/sedoo.jpeg";
import dorcas2 from "../images/dorcas2.jpeg";

function Mission() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <main className="mb-[120px] p-2">
        {/* our team */}
        <div className="h-[70px] bg-green-600 flex items-center justify-center mb-4">
          <h1 className="text-[20px] font-[500] my-4 p-2 text-white text-center uppercase">
            Our Team
          </h1>
        </div>{" "}
        <div className="p-[10px] md:px-[60px] lg:px-[60px] my-4">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-4 lg:grid-cols-4">
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={woman3}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-contain"
              />
              <h1 className="my-[6px] mx-auto">SALOME AGBAJI</h1>
              <p className="text-[12px] text-gray-500 mx-auto">
                FOUNDER/CHAIRPERSON VIRTUOUS WOMEN OF HOPE CENTER / BOARD OF
                TRUSTEES VIRTUOUS WOMEN CREDIT AND LOAN COOPERATIVE SOCIETY
              </p>
            </div>

            <div className="flex flex-col rounded shadow p-3">
              <img
                src={woman1}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto">ULAN JATAU</h1>
              <p className="text-[12px] text-gray-500 mx-auto">
                PRESIDENT VIRTUOUS WOMEN CREDIT AND LOAN COOPERATIVE SOCIETY
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={grace}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">
                Grace Piomoki-Stevens
              </h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Vice President Virtuous Women Credit and Loan Cooperative
                Society
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={woman2}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto">GLADYS ADENIKINJU</h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                TREASURER Virtuous women Credit and loan cooperative society
              </p>
            </div>
            {/*  */}
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={peace}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">Peace Ewuga </h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Secretary Virtuous Women Credit and Loans Cooperative Society{" "}
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={dorcas2}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">
                Uchangal Dorcas Pwajok.
              </h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Financial Secretary Virtuous Women Credit and Loans Cooperative
                Society
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={dorcas}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">Dorcas Nyam</h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Secretary to the board of Trustees Virtuous Women of Hope Center{" "}
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={sedoo}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">Sedoo Ibi</h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Member, Board of Trustees Virtuous Women of Hope Center{" "}
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Mission;
