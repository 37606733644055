/** @format */
import React from "react";
import Logo from "../images/white.png";
import { Link } from "react-router-dom";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";

function Footer() {
  function getCurrentYear() {
    return new Date().getFullYear();
  }
  const currentYear = getCurrentYear();

  return (
    <div className=" bg-green-600 p-2 pb-4">
      <img
        src={Logo}
        alt=""
        className="h-[160px] w-[160px] md:h-[240px] md:w-[240px] rounded-full -ml-[20px] md:ml-[150px] lg:ml-[150px]"
      />
      <div className="flex flex-col items-start md:flex md:flex-row md:justify-around mb-4">
        <div className="text-white">
          <h1 className="text-[22px] font-[500] my-[8px]">Services</h1>
          <div>
            <p className="text-[14px] my-[4px]">Tax Planning</p>
            <p className="text-[14px] my-[4px]">Tax Health Check</p>
            <p className="text-[14px] my-[4px]">Transfer Pricing</p>
            <p className="text-[14px] my-[4px]">
              Payroll Restructuring & Management
            </p>
            <p className="text-[14px] my-[4px]">
              Tax implication on implementation of contracts and other services
            </p>
          </div>
        </div>
        <div className="text-white">
          <h1 className="text-[22px] font-[500] my-[8px]">Useful Links</h1>
          <div className="flex flex-col">
            <Link className="text-[14px] my-[4px]" to="/">
              About
            </Link>
            {/* <Link className="text-[14px] my-[4px]" to="/">
              Meet The Team
            </Link> */}
            {/* <Link className="text-[14px] my-[4px]" to="/documentary">
              Documentary
            </Link> */}
          </div>
        </div>
        <div className="text-white">
          <h1 className="text-[22px] font-[500] my-[8px]">Join Us</h1>
          <div className="flex flex-row space-x-4">
            <Link className="text-[14px] my-[4px]" target="_blank" to="">
              <BsFacebook size={20} />
            </Link>
            <Link className="text-[14px] my-[4px]" to="/">
              <FaLinkedin size={20} />
            </Link>
            <Link className="text-[14px] my-[4px]" to="/">
              <FaInstagram size={20} />
            </Link>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col md:flex-row w-full justify-around border-t pt-3 border-gray-300 ">
        <p className="text-[14px] text-white">
          Copyright &copy; {currentYear} Henry Edah & Co
        </p>
        <p className="text-white flex flex-row items-center">
          <FaLocationDot />
          <p className="text-[12px] md:text-[14px]">6 Kufang Close, Miango Road Jos. Plateau State</p>
        </p>
      </div>
    </div>
  );
}

export default Footer;
