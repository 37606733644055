/** @format */
import React, { useState, useRef } from "react";
import image2 from "../images/productivity.jpg";
import image3 from "../images/new-black.jpeg";
import Footer from "../components/Footer";
import Logo from "../images/white.png";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdChevronRight } from "react-icons/md";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";

function Home() {
  const [showNav, setShowNav] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const formData = {
    name,
    email: "info@henryedahco.com",
    message: message + `  ( Sender Details: from ${email} by ${name} )`,
  };

  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  // const sectionThreeRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const sendEmail = () => {
    if (!name.trim()) {
      return toast.error("Name is required");
    }
    if (!email.trim()) {
      return toast.error("Email is required");
    }
    if (!message.trim()) {
      return toast.error("Message is required");
    }
    setLoading(true);
    emailjs
      .send(
        "service_c8op9w7",
        "template_mt2nago",
        formData,
        "DEFHrVm5FfEZ1FKl9"
      )
      .then((result) => {
        console.log(result.text);
        toast.success("Message Sent Successfully.");
        setLoading(false);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.log(error.text);
        alert("Failed to send message. Please try again later.");
        setLoading(false);
      });
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <nav className="h-[60px] md:h-[90px] px-4 flex items-center justify-between md:justify-around shadow bg-green-600">
        <Link to="/">
          <img
            src={Logo}
            alt=""
            className="h-[100px] w-[100px] md:h-[200px] md:w-[200px]"
          />
        </Link>
        <div className="hidden md:flex md:space-x-4 md:items-center lg:flex lg:space-x-4 lg:items-center">
          <button
            to={"#aboutus"}
            onClick={() => scrollToSection(sectionOneRef)}
            className="text-white font-[500]"
          >
            About
          </button>
          <button
            to={"/meet-the-team"}
            onClick={() => scrollToSection(sectionTwoRef)}
            className="text-white font-[500]"
          >
            Contact Us
          </button>
        </div>
        {/* responsive nav */}
        {showNav && (
          <div
            data-aos="fade-down"
            className="absolute md:hidden lg:hidden flex flex-col p-2 bg-green-600 text-white left-0 right-0 top-[60px] z-10 border-t border-white"
          >
            <button
              to="/about"
              className="flex items-center justify-between my-[8px] px-2"
              onClick={() => {
                scrollToSection(sectionOneRef);
                setShowNav(false);
              }}
            >
              <p>About</p>
              <MdChevronRight />
            </button>
            <button
              to="/meet-the-team"
              className="flex items-center justify-between my-[8px] px-2"
              onClick={() => {
                scrollToSection(sectionTwoRef);
                setShowNav(false);
              }}
            >
              <p>Contact Us</p>
              <MdChevronRight />
            </button>
            {/* <button
              to="/documentary"
              className="flex items-center justify-between my-[8px] px-2"
            >
              <p>Documentary</p>
              <MdChevronRight />
            </button> */}
          </div>
        )}
        <RxHamburgerMenu
          size={23}
          className="md:hidden lg:hidden text-white"
          onClick={() => setShowNav((prev) => (prev = !prev))}
        />
      </nav>
      <main className="relative">
        <img
          src={image2}
          alt=""
          className="w-full h-[470px] object-cover -mb-[150px]"
        />
        <div className="text-white mb-[50px] backdrop-blur-lg w-full rounded flex items-center justify-center h-[130px] md:w-[650px] md:ml-[70px] lg:w-[650px] lg:ml-[70px] md:h-[130px] md:p-4 lg:h-[130px] lg:p-4">
          <p className="text-[14px] p-2 md:p-3 md:text-[17px] bg-black rounded border-2 border-white">
            Henry Edah & Co is a Tax and Management Consultancy Firm providing
            tax and consultancy services to private companies, government
            institutions and individuals.
          </p>
        </div>
      </main>
      {/* programs */}
      <div className="p-[10px] md:px-[60px] lg:px-[60px]">
        <h1 className="text-[20px] font-[700] text-green-600 border-b-2 border-green-500 pb-[8px]">
          Services
        </h1>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 mt-6">
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={operations} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] mb-[4px] ">
                Tax Planning
              </h1>
              <p className="text-[14.5px] text-gray-600">
                Our tax planning services help individuals and businesses
                minimize their tax liabilities through effective strategies. We
                ensure compliance with tax laws while optimizing your financial
                position for long-te rm benefits.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={business} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] mb-[4px]">
                Tax Health Check
              </h1>
              <p className="text-[14.5px] text-gray-600">
                We conduct comprehensive tax health checks to identify potential
                compliance issues and areas for tax savings. Our detailed
                reviews help mitigate risks and ensure your tax filings are
                accurate and up-to-date
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={agric} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] mb-[4px]">
                Transfer Pricing
              </h1>
              <p className="text-[14.5px] text-gray-600">
                Our transfer pricing solutions ensure that your inter-company
                transactions comply with international tax regulations. We help
                you navigate complex rules to avoid penalties and enhance
                profitability.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={human} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] mb-[4px]">
                Payroll Restructuring & Management
              </h1>
              <p className="text-[14.5px] text-gray-600">
                We offer expert payroll restructuring and management services to
                streamline your operations and ensure compliance with tax and
                labor regulations. Our solutions improve efficiency while
                optimizing costs.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={human} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] mb-[4px]">
                Tax implication on implementation of contracts and other
                services
              </h1>
              <p className="text-[14.5px] text-gray-600">
                Understanding the tax implications of contracts is crucial for
                avoiding unnecessary costs. We provide insights and advice on
                how to structure contracts to minimize tax liabilities while
                ensuring compliance..
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* about us */}
      <div className=" mb-4" id="aboutus" ref={sectionOneRef}>
        <div className="p-[10px] md:px-[60px] lg:px-[60px]">
          <h1 className="text-[20px] font-[600] my-4 p-2 text-green text-start text-green-600 border-b-2 border-green-500">
            About Us
          </h1>
        </div>{" "}
        <h1 className=" text-gray-600 px-4 md:px-14 text-start">
          Henry Edah & Co is a Tax and Management Consultancy Firm providing tax
          and consultancy services to private companies, government institutions
          and individuals.{" "}
        </h1>
        <div className="p-4">
          <h1 className="uppercase text-[18px] my-2 text-start md:px-10">
            our mission
          </h1>
          <p className="text-gray-600 text-start md:px-10">
            To provide Businesses and Individuals with the best, timely and most
            efficient Tax Advisory and Tax Related Services.
          </p>
        </div>
      </div>
      {/* client sectors */}
      <div className="p-[10px] md:px-[60px] lg:px-[60px]">
        <h1 className="text-[20px] font-[600] my-4 p-2 text-green text-start text-green-600 border-b-2 border-green-500">
          Client Sectors
        </h1>
      </div>

      <div className="p-[10px] md:px-[60px] lg:px-[60px]">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 mt-6">
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={operations} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[600] mb-[4px] text-green-700">
                Hospitality
              </h1>
              <p className="text-[14.5px] text-gray-600">
              We work closely with hotels, restaurants, and other hospitality businesses to provide strategic tax planning and financial management solutions. Our services help streamline operations, optimize resources, and ensure regulatory compliance
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={business} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[600] mb-[4px] text-green-700">
              Telecommunications
              </h1>
              <p className="text-[14.5px] text-gray-600">
              Our consultancy services for telecom companies focus on helping them navigate complex tax laws and financial regulations. We provide insights into cost management, transfer pricing, and strategic planning to enhance profitability and ensure compliance.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={agric} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[600] mb-[4px] text-green-700">
              Government Parastatals
              </h1>
              <p className="text-[14.5px] text-gray-600">
              We offer expert tax advisory and management services to government institutions and parastatals, ensuring efficient use of public resources, compliance with tax laws, and support for sustainable development initiatives.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={human} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] mb-[4px] text-green-600">
              Financial Services
              </h1>
              <p className="text-[14.5px] text-gray-600">
              Our firm supports financial institutions with tax-efficient structures, compliance strategies, and risk management, ensuring they meet their regulatory obligations while maximizing their financial health.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={human} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] mb-[4px] text-green-600">
              Real Estate
              </h1>
              <p className="text-[14.5px] text-gray-600">
              We assist healthcare providers, clinics, and pharmaceutical companies with comprehensive tax solutions that align with industry regulations, reduce liabilities, and improve financial performance.
              </p>
            </div>
            
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={human} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] mb-[4px] text-green-600">
              Private Sectors
              </h1>
              <p className="text-[14.5px] text-gray-600">
              We assist private sector organizations in various industries with customized tax planning, compliance, and management consultancy. Our services are designed to help businesses optimize their financial health and achieve sustainable growth.              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="shadow flex flex-col rounded border-l-2 border-green-600 p-2"
          >
            {/* <img src={human} alt="" className="h-[130px] mx-auto" /> */}
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] mb-[4px] text-green-600">
              Individuals
              </h1>
              <p className="text-[14.5px] text-gray-600">
              Our firm offers personal tax advisory and planning services to individuals, helping them navigate tax laws, maximize savings, and ensure compliance. We work to provide financial clarity and support long-term financial goals.              </p>
            </div>
            
          </div>
        </div>
      </div>

      {/* who we are */}
      <div className="p-[10px] md:px-[60px] lg:px-[60px]">
        <h1 className="text-[20px] font-[600] my-4 p-2 text-center text-green-600">
          Who We Are
        </h1>
      </div>
      <div className="p-[10px] md:px-[60px] lg:px-[60px] flex flex-col items-center  md:flex-row justify-around md:justify-center">
        <img
          src={image3}
          alt=""
          className="h-[370px] w-[370px] md:h-[500px] md:w-[600px] object-contain"
        />
        <div className="md:w-[40%] md:ml-8">
          <h1 className="font-[500] mb-3">
            Henry Edah & Co is a Tax and Management Consultancy Firm providing
            tax and consultancy services to private companies, government
            institutions and individuals.
          </h1>
          <p className="text-gray-500">
            Henry Edah & Co is a renowned Tax and Management Consultancy Firm
            committed to delivering exceptional tax advisory and management
            consulting services to private companies, government institutions,
            and individuals. With a deep understanding of the complexities of
            taxation and the evolving regulatory landscape, we provide tailored
            solutions designed to help our clients achieve compliance, optimize
            their tax strategies, and enhance overall financial performance. Our
            team of experienced professionals works closely with each client to
            address their unique needs, offering expertise in tax planning,
            auditing, compliance, and management consultancy. Whether you are a
            growing business, a government entity, or an individual seeking
            sound financial guidance, Henry Edah & Co is dedicated to helping
            you navigate the intricacies of taxation and management to achieve
            your goals efficiently and effectively.
          </p>
        </div>
      </div>
      {/* our team */}
      {/* <main className="mb-[120px] p-2" ref={sectionTwoRef}>
        <div className="h-[70px] bg-green-600 flex items-center justify-center mb-4">
          <h1 className="text-[20px] font-[500] my-4 p-2 text-white text-center uppercase">
            Our Team
          </h1>
        </div>{" "}
        <div className="p-[10px] md:px-[60px] lg:px-[60px] my-4">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-4 lg:grid-cols-4">
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={woman3}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-contain"
              />
              <h1 className="my-[6px] mx-auto">SALOME AGBAJI</h1>
              <p className="text-[12px] text-gray-500 mx-auto">
                FOUNDER/CHAIRPERSON Henry Edah & Co OF HOPE CENTER / BOARD OF
                TRUSTEES VIRTUOUS WOMEN CREDIT AND LOAN COOPERATIVE SOCIETY
              </p>
            </div>

            <div className="flex flex-col rounded shadow p-3">
              <img
                src={woman1}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto">ULAN JATAU</h1>
              <p className="text-[12px] text-gray-500 mx-auto">
                PRESIDENT VIRTUOUS Henry Edah and Co AND LOAN COOPERATIVE
                SOCIETY
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={grace}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">
                Grace Piomoki-Stevens
              </h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Vice President Henry Edah Credit and Loan Cooperative Society
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={woman2}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto">GLADYS ADENIKINJU</h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                TREASURER Henry Edah & Co and loan cooperative society
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={peace}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">Peace Ewuga </h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Secretary Henry Edah & Co Credit and Loans Cooperative Society{" "}
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={dorcas2}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">
                Uchangal Dorcas Pwajok.
              </h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Financial Henry Edah & Co Women Credit and Loans Cooperative
                Society
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={dorcas}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">Dorcas Nyam</h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Secretary to the board of Trustees Henry Edah & Co of Hope
                Center{" "}
              </p>
            </div>
            <div className="flex flex-col rounded shadow p-3">
              <img
                src={sedoo}
                alt=""
                className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
              />
              <h1 className="my-[6px] mx-auto uppercase">Sedoo Ibi</h1>
              <p className="text-[12px] text-gray-500 mx-auto uppercase">
                Member, Board of Trustees Virtuous Women of Hope Center{" "}
              </p>
            </div>
          </div>
        </div>
      </main> */}

      {/* contact us form */}
      <div className="mt-2 mb-8" ref={sectionTwoRef}>
        <div className="p-[10px] md:px-[60px] lg:px-[60px]">
          <h1 className="text-[20px] font-[600] my-4 p-2 text-green text-start text-green-600 border-b-2 border-green-500">
            Contact Us
          </h1>
        </div>
        <div className="p-2 md:p-4 max-w-[700px] mx-auto">
          <input
            className="h-[50px] w-full border border-green-600 rounded outline-none text-[16px] p-[10px] mb-3"
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="h-[50px] w-full border border-green-600 rounded outline-none text-[16px] p-[10px] mb-3"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Message..."
            className="h-[200px] w-full border border-green-600 rounded outline-none text-[16px] p-[10px] mb-3 resize-none"
          ></textarea>
          <button
            onClick={sendEmail}
            className="h-[53px] w-full border bg-green-600 rounded outline-none text-[17px] p-[10px] mb-3 text-white font-500"
          >
            {loading ? "sending..." : "Submit"}
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
