/** @format */
import { useState } from "react";
import Logo from "../images/henry.png";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdChevronRight } from "react-icons/md";

function Navbar() {
  const [showNav, setShowNav] = useState(false);

  return (
    <nav className="h-[90px] px-4 flex items-center justify-around shadow bg-green-600">
      <Link to="/">
        <img
          src={Logo}
          alt=""
          className="h-[20px] w-[150px]"
        />
      </Link>
      <div className="hidden md:flex md:space-x-4 md:items-center lg:flex lg:space-x-4 lg:items-center">
        <Link to={"#aboutus"} className="text-white font-[500]">
          About
        </Link>
        <Link to={"/meet-the-team"} className="text-white font-[500]">
          Meet The Team
        </Link>
        <Link to={"/documentary"} className="text-white font-[500]">
          Documentary
        </Link>
      </div>
      {/* responsive nav */}
      {showNav && (
        <div
          data-aos="fade-down"
          className="absolute md:hidden lg:hidden flex flex-col p-2 bg-green-600 text-white left-0 right-0 top-[88px] z-10 border-t border-white"
        >
          <Link
            to="/about"
            className="flex items-center justify-between my-[8px] px-2"
          >
            <p>About</p>
            <MdChevronRight />
          </Link>
          <Link
            to="/meet-the-team"
            className="flex items-center justify-between my-[8px] px-2"
          >
            <p>Meet The Team</p>
            <MdChevronRight />
          </Link>
          <Link
            to="/documentary"
            className="flex items-center justify-between my-[8px] px-2"
          >
            <p>Documentary</p>
            <MdChevronRight />
          </Link>
        </div>
      )}
      <RxHamburgerMenu
        className="md:hidden lg:hidden text-white"
        onClick={() => setShowNav((prev) => (prev = !prev))}
      />
    </nav>
  );
}

export default Navbar;
