/** @format */
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import About from "./pages/About";
import Mission from "./pages/Mission";
import Documentary from "./pages/Documentary";
// ..
AOS.init();

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/meet-the-team" element={<Mission />} />
          <Route path="/documentary" element={<Documentary />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
