/** @format */
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import image3 from "../images/image4.jpeg";
import business from "../images/business.jpg";
import agric from "../images/agric.jpg";
import human from "../images/human.jpg";
import operations from "../images/operations.jpg";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <main className="mb-[1px]">
        <img src={image3} alt="" className="w-full h-[570px] object-cover" />
      </main>
      {/* about us */}
      <div className="shadow rounded mb-4">
        <div className="h-[70px] bg-green-600 flex items-center justify-center mb-4">
          <h1 className="text-[20px] font-[500] my-4 p-2 text-white text-center uppercase">
            About Us
          </h1>
        </div>{" "}
        <h1 className="p-2 text-gray-600">
          VVirtuous Women of Hope Center is an organization based in Nigeria
          with its headquarters in Jos. It is committed to providing women with
          sustainable solutions through capacity building.{" "}
        </h1>
        <div className="p-2">
          <h1 className="uppercase text-[18px] my-2">our vision</h1>
          <p className="text-gray-600">
            To have a nation free of poverty among women.
          </p>
          <h1 className="uppercase text-[18px] my-2">our mission</h1>
          <p className="text-gray-600">
            To facilitate a women development programme through collaborations
            and partnerships with public, private organizations and individuals.
          </p>
          <h1 className="uppercase text-[18px] my-2">our goal</h1>
          <p className="text-gray-600">
            To empower women; particularly widows and the less privileged.
          </p>
        </div>
      </div>
      {/* programs */}
      <div className="p-[10px] md:px-[60px] lg:px-[60px]">
        <h1 className="text-[20px] font-[500]">Programs</h1>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 mt-6">
          <div data-aos="fade-up" className="shadow flex flex-col rounded">
            <img src={operations} alt="" className="h-[130px] mx-auto" />
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] my-[4px]">
                Agric Training
              </h1>
              <p className="text-[14.5px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam,
                nisi doloribus, cupiditate temporibus iure consequuntur illo
                quod magni, voluptas cumque dignissimos distinctio. Numquam,
                dolor? Libero et accusamus exercitationem autem culpa.
              </p>
            </div>
          </div>
          <div data-aos="fade-up" className="shadow flex flex-col rounded">
            <img src={business} alt="" className="h-[130px] mx-auto" />
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] my-[4px]">
                Entrepreneurship Training
              </h1>
              <p className="text-[14.5px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam,
                nisi doloribus, cupiditate temporibus iure consequuntur illo
                quod magni, voluptas cumque dignissimos distinctio. Numquam,
                dolor? Libero et accusamus exercitationem autem culpa.
              </p>
            </div>
          </div>
          <div data-aos="fade-up" className="shadow flex flex-col rounded">
            <img src={agric} alt="" className="h-[130px] mx-auto" />
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] my-[4px]">
                Women and Orphans Support
              </h1>
              <p className="text-[14.5px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam,
                nisi doloribus, cupiditate temporibus iure consequuntur illo
                quod magni, voluptas cumque dignissimos distinctio. Numquam,
                dolor? Libero et accusamus exercitationem autem culpa.
              </p>
            </div>
          </div>
          <div data-aos="fade-up" className="shadow flex flex-col rounded">
            <img src={human} alt="" className="h-[130px] mx-auto" />
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] my-[4px]">
                Business Training
              </h1>
              <p className="text-[14.5px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam,
                nisi doloribus, cupiditate temporibus iure consequuntur illo
                quod magni, voluptas cumque dignissimos distinctio. Numquam,
                dolor? Libero et accusamus exercitationem autem culpa.
              </p>
            </div>
          </div>
          <div data-aos="fade-up" className="shadow flex flex-col rounded">
            <img src={human} alt="" className="h-[130px] mx-auto" />
            <div className="p-[12px]">
              <h1 className="text-start text-[17px] font-[500] my-[4px]">
                Mentorship Training
              </h1>
              <p className="text-[14.5px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam,
                nisi doloribus, cupiditate temporibus iure consequuntur illo
                quod magni, voluptas cumque dignissimos distinctio. Numquam,
                dolor? Libero et accusamus exercitationem autem culpa.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
