/** @format */
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import image7 from "../images/image7.jpeg";
import image8 from "../images/image8.jpeg";
import image9 from "../images/image9.jpeg";

function Documentary() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <video
        autoPlay={true}
        src="https://firebasestorage.googleapis.com/v0/b/linked-in-app-60b20.appspot.com/o/video.mp4?alt=media&token=8e4d5e0c-3ec7-4489-af88-1183ed939618&_gl=1*2zi4r4*_ga*MTUxMDc4MjM0My4xNjY1MjI3Njc5*_ga_CW55HF8NVT*MTY4NjA1MTA0NC42LjEuMTY4NjA1Mzk1MS4wLjAuMA.."
        className="object-contain w-full md:h-[600px] md:object-cover lg:h-[600px] lg:object-cover"
      ></video>
      <div className="p-2 my-4">
        <h1 className="text-[23px] my-6">Outreach</h1>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3">
          <div>
            <img
              src={image7}
              alt=""
              className="h-[250px] w-full object-cover rounded"
            />
          </div>
          <div>
            <img
              src={image8}
              alt=""
              className="h-[250px] w-full object-cover rounded"
            />
          </div>
          <div>
            <img
              src={image9}
              alt=""
              className="h-[250px] w-full object-cover rounded"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Documentary;
